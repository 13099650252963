import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import routes from "./pathes";
import store from "@/store";
import configs from "../configs";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

Vue.use(Meta);

function redirectUser() {
  if (configs.env === "prod" || configs.env === "homol") {
    return (window.location.href = configs.portal_url);
  } else {
    return router.push("/login");
  }
}

async function getHashAuth(token) {
  try {
    const res = await Vue.prototype.$http.post(
      Vue.prototype.$ipUser + "user/get-hash",
      { token }
    );
    if (res.data.hash) {
      return "success";
    } else {
      return "login";
    }
  } catch (err) {
    console.log("error: ", err);
    return err;
  }
}

async function isValidToken(tokenExp) {
  let now = Math.floor(Date.now() / 1000);
  return now - tokenExp <= 0;
}

router.beforeEach(async (to, from, next) => {
  if (to.name === "Login" && !to?.query?.token) {
    if (store.getters["auth/isAuthenticated"]) {
      return router.push("/");
    }
  } else if (to?.query?.token) {
    let hash = null;
    let url = new URL(window.location.href);
    let hashUrl = false;
    let hashStore = false;
    let isValidSessionToken = false;
    let isValidHash = false;

    //if (url.pathname !== "/") this.setUrlPathName(url.pathname);

    hashUrl = !!url.searchParams.get("token");

    if (hashUrl) {
      hash = url.searchParams.get("token");
    } else {
      hash = this.getHash;

      if (hash) {
        hashStore = true;
      }
    }

    if (hash) {
      // eslint-disable-next-line no-inner-declarations
      async function isValidHashFn(hash) {
        try {
          const res = await Vue.prototype.$http.post(
            Vue.prototype.$ipUser + "user/validate-hash",
            {
              ...hash,
            }
          );
          if (res) {
            return !!res.data.isValidHash;
          }
        } catch (err) {
          Vue.prototype.$fnError(err);
          return false;
        }
      }

      isValidHash = await isValidHashFn({ hash });
    }

    let tokenExp = store.getters["auth/getTokenExp"];
    if (tokenExp) {
      isValidSessionToken = await isValidToken(tokenExp);
    }

    if (!hashUrl && !hashStore && !isValidSessionToken && !isValidHash) {
      return this.setInvalidHashDialog(true);
    }

    if (hashUrl && isValidHash) {
      const auth = await store.dispatch("auth/hashAuthenticate", hash);
      if (auth === "success") {
        return router.push(to.path);
      } else {
        Vue.prototype.$toast.error("Não foi possível realizar o login");
        return redirectUser();
      }
    }

    if (hashStore && isValidHash && !isValidSessionToken) {
      Vue.prototype.$toast.error("Não foi possível realizar o login");
      return redirectUser();
    }

    if ((hashUrl || hashStore) && !isValidHash && !isValidSessionToken) {
      Vue.prototype.$toast.error("Não foi possível realizar o login");
      return redirectUser();
    }

    if (!isValidHash && isValidSessionToken) {
      const auth = await getHashAuth(to?.query?.token);
      if (auth === "success") {
        return router.push("/login");
      } else if (auth === "login") {
        Vue.prototype.$toast.error("Não foi possível realizar o login");
        return redirectUser();
      } else {
        let err = auth;
        Vue.prototype.$fnError(err);
      }
    }

    return redirectUser();
  } else if (!store.getters["auth/isAuthenticated"]) {
    return redirectUser();
  }
  next();
});

const defaultTitle = "Torii Nissin - Znap Technologies";
router.afterEach((to) => {
  Vue.nextTick(() => {
    if (to.meta.title) {
      document.title = `${to.meta.title} - ${defaultTitle}`;
      return;
    }

    let getModule = store.getters["auth/getModules"] || [];
    let getMenu = store.getters["auth/getMenu"] || [];

    if (getMenu.length && getModule.length) {
      let submenu = getMenu.find((m) => {
        if (m.frontend_route_path) {
          return (
            to.fullPath.split("/")[2] === m.frontend_route_path.replace("/", "")
          );
        }
      });

      let module = getModule.find((m) => {
        if (m.path) {
          return to.fullPath.split("/")[1] === m.path.replace("/", "");
        }
      });

      if (submenu && module) {
        const { label } = submenu;
        document.title = `${label} - ${module.label} ${defaultTitle}`;
      } else {
        document.title = defaultTitle;
      }
    } else {
      document.title = defaultTitle;
    }
  });
});

export default router;
