export default [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      requireAuth: false,
      redirectHomeIfLogged: true,
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    path: "/security/",
    name: "Security",
    component: () =>
      import(/* webpackChunkName: "security" */ "../views/Security.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    children: [
      {
        path: "user",
        name: "user",
        component: () =>
          import(/* webpackChunkName: "users" */ "../views/Security/users.vue"),
      },
      {
        path: "group",
        name: "group",
        component: () =>
          import(/* webpackChunkName: "group" */ "../views/Security/Group.vue"),
      },
      {
        path: "group-transaction",
        name: "group-transaction",
        component: () =>
          import(
            /* webpackChunkName: "groupTransaction" */ "../views/Security/accessProfile.vue"
          ),
      },
      {
        path: "group-type",
        name: "group-type",
        component: () =>
          import(
            /* webpackChunkName: "groupType" */ "../views/Security/GroupType.vue"
          ),
      },
      {
        path: "user-group",
        name: "user-group",
        component: () =>
          import(
            /* webpackChunkName: "userGroup" */ "../views/Security/userProfile.vue"
          ),
      },
      {
        path: "user-log-access",
        name: "user-log-access",
        component: () =>
          import(
            /* webpackChunkName: "userLogAccess" */ "../views/Security/accessReports.vue"
          ),
      },
      {
        path: "user-log-activity",
        name: "user-log-activity",
        component: () =>
          import(
            /* webpackChunkName: "userLogActivity" */ "../views/Security/UserLogActivity.vue"
          ),
      },
      {
        path: "transaction",
        name: "transaction",
        component: () =>
          import(
            /* webpackChunkName: "transaction" */ "../views/Security/Transaction.vue"
          ),
      },
    ],
  },
  {
    path: "/masterdata/",
    name: "MasterData",
    component: () =>
      import(/* webpackChunkName: "masterdata" */ "../views/MasterData.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    children: [
      {
        path: "extract-payment-action-type",
        name: "extract-payment-action-type",
        component: () =>
          import(
            /* webpackChunkName: "extract-payment-action-type" */ "../views/Refund/ExtractPaymentActionType.vue"
          ),
      },
      {
        path: "extract-planning-product",
        name: "extract-planning-product",
        component: () =>
          import(
            /* webpackChunkName: "extract-payment-action-type" */ "../views/Refund/ExtractPlanningProduct.vue"
          ),
      },
      {
        path: "extract-planning-product-historic",
        name: "extract-planning-product-historic",
        component: () =>
          import(
            /* webpackChunkName: "extract-payment-action-type" */ "../views/Refund/ExtractPlanningProductHistoric.vue"
          ),
      },
      {
        path: "payment-type",
        name: "payment-type",
        component: () =>
          import(
            /* webpackChunkName: "extract-payment-action-type" */ "../views/Refund/PaymentType.vue"
          ),
      },
      {
        path: "price-type",
        name: "price-type",
        component: () =>
          import(
            /* webpackChunkName: "extract-payment-action-type" */ "../views/Refund/PriceType.vue"
          ),
      },
      {
        path: "customer-type",
        name: "customer-type",
        component: () =>
          import(
            /* webpackChunkName: "customer-type" */ "../views/MasterData/CustomerType.vue"
          ),
      },
      {
        path: "customer",
        name: "customer",
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "../views/MasterData/Customer.vue"
          ),
      },
      {
        path: "approval-group-area-name",
        name: "approval-group-area-name",
        component: () =>
          import(
            /* webpackChunkName: "sales-force-president" */ "../views/MasterData/ApprovalGroupAreaName.vue"
          ),
      },
      {
        path: "sales-force-type",
        name: "sales-force-type",
        component: () =>
          import(
            /* webpackChunkName: "sales-force-type" */ "../views/MasterData/SalesForceType.vue"
          ),
      },
      {
        path: "sales-force",
        name: "sales-force",
        component: () =>
          import(
            /* webpackChunkName: "sales-force" */ "../views/MasterData/SalesForce.vue"
          ),
      },
      {
        path: "sales-force-regional",
        name: "sales-force-regional",
        component: () =>
          import(
            /* webpackChunkName: "sales-force-regional" */ "../views/MasterData/SalesForceRegional.vue"
          ),
      },
      {
        path: "sales-force-region",
        name: "sales-force-region",
        component: () =>
          import(
            /* webpackChunkName: "sales-force-region" */ "../views/MasterData/SalesForceRegion.vue"
          ),
      },
      {
        path: "sales-force-ggc",
        name: "sales-force-ggc",
        component: () =>
          import(
            /* webpackChunkName: "sales-force-ggc" */ "../views/MasterData/SalesForceGgc.vue"
          ),
      },
      {
        path: "sales-force-director",
        name: "sales-force-director",
        component: () =>
          import(
            /* webpackChunkName: "sales-force-director" */ "../views/MasterData/SalesForceDirector.vue"
          ),
      },
      {
        path: "sales-force-president",
        name: "sales-force-president",
        component: () =>
          import(
            /* webpackChunkName: "sales-force-president" */ "../views/MasterData/SalesForcePresident.vue"
          ),
      },
      {
        path: "sales-force-gne",
        name: "sales-force-gne",
        component: () =>
          import(
            /* webpackChunkName: "sales-force-gne" */ "../views/MasterData/SalesForceGne.vue"
          ),
      },
      {
        path: "sales-force-gnv",
        name: "sales-force-gnv",
        component: () =>
          import(
            /* webpackChunkName: "sales-force-gnv" */ "../views/MasterData/SalesForceGnv.vue"
          ),
      },
      {
        path: "sales-force-gre",
        name: "sales-force-gre",
        component: () =>
          import(
            /* webpackChunkName: "sales-force-gre" */ "../views/MasterData/SalesForceGre.vue"
          ),
      },
      {
        path: "distributor",
        name: "distributor",
        component: () =>
          import(
            /* webpackChunkName: "distributor" */ "../views/MasterData/Distributor.vue"
          ),
      },
      {
        path: "customer-distributor",
        name: "customer-distributor",
        component: () =>
          import(
            /* webpackChunkName: "customer-distributor" */ "../views/MasterData/CustomerDistributor.vue"
          ),
      },
      {
        path: "sales-force-distributor",
        name: "sales-force-distributor",
        component: () =>
          import(
            /* webpackChunkName: "sales-force-distributor" */ "../views/MasterData/SalesForceDistributor.vue"
          ),
      },
      {
        path: "user-substitute",
        name: "user-substitute",
        component: () =>
          import(
            /* webpackChunkName: "sales-force-distributor" */ "../views/MasterData/UserSubstitute.vue"
          ),
      },
      {
        path: "contact",
        name: "contact",
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "../views/MasterData/Contact.vue"
          ),
      },
      {
        path: "packaging-type",
        name: "packaging-type",
        component: () =>
          import(
            /* webpackChunkName: "packaging-type" */ "../views/MasterData/PackagingType.vue"
          ),
      },
      {
        path: "product-flavor",
        name: "product-flavor",
        component: () =>
          import(
            /* webpackChunkName: "product-flavor" */ "../views/MasterData/ProductFlavor.vue"
          ),
      },
      {
        path: "emails-approval-level",
        name: "emails-approval-level",
        component: () =>
          import(
            /* webpackChunkName: "product-flavor" */ "../views/MasterData/EmailsApprovalLevel.vue"
          ),
      },
      {
        path: "product-family",
        name: "product-family",
        component: () =>
          import(
            /* webpackChunkName: "product-family" */ "../views/MasterData/ProductFamily.vue"
          ),
      },
      {
        path: "product-family-com",
        name: "product-family-com",
        component: () =>
          import(
            /* webpackChunkName: "product-family-com" */ "../views/MasterData/ProductFamilyCom.vue"
          ),
      },
      {
        path: "product-price",
        name: "product-price",
        component: () =>
          import(
            /* webpackChunkName: "product-price" */ "../views/MasterData/ProductPrice.vue"
          ),
      },
      {
        path: "product-price-item",
        name: "product-price-item",
        component: () =>
          import(
            /* webpackChunkName: "product-price-item" */ "../views/MasterData/ProductPriceItem.vue"
          ),
      },
      {
        path: "products-disactivated",
        name: "products-disactivated",
        component: () =>
          import(
            /* webpackChunkName: "product-price-item" */ "../views/MasterData/ProductDisactivated.vue"
          ),
      },
      {
        path: "product",
        name: "product",
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/MasterData/Product.vue"
          ),
      },
      {
        path: "distributor-product-price",
        name: "distributor-product-price",
        component: () =>
          import(
            /* webpackChunkName: "distributor-product-price" */ "../views/MasterData/DistributorProductPrice.vue"
          ),
      },
      {
        path: "group-distributor",
        name: "group-distributor",
        component: () =>
          import(
            /* webpackChunkName: "group-distributor" */ "../views/MasterData/GroupDistributor.vue"
          ),
      },
      {
        path: "period-control",
        name: "period-control",
        component: () =>
          import(
            /* webpackChunkName: "period-control" */ "../views/MasterData/PeriodControl.vue"
          ),
      },
      {
        path: "action",
        name: "action",
        component: () =>
          import(
            /* webpackChunkName: "action" */ "../views/MasterData/Action.vue"
          ),
      },
      {
        path: "subgroup",
        name: "subgroup",
        component: () =>
          import(
            /* webpackChunkName: "subgroup" */ "../views/MasterData/Subgroup.vue"
          ),
      },
      {
        path: "action-subgroup",
        name: "action-subgroup",
        component: () =>
          import(
            /* webpackChunkName: "action-subgroup" */ "../views/MasterData/ActionSubgroup.vue"
          ),
      },
      {
        path: "fund-type-combination",
        name: "fund-type-combination",
        component: () =>
          import(
            /* webpackChunkName: "fund-type" */ "../views/MasterData/FundTypeCombination.vue"
          ),
      },
      {
        path: "fund-type",
        name: "fund-type",
        component: () =>
          import(
            /* webpackChunkName: "fund-type" */ "../views/MasterData/FundType.vue"
          ),
      },
      {
        path: "fund-combination",
        name: "fund-combination",
        component: () =>
          import(
            /* webpackChunkName: "fund-combination" */ "../views/MasterData/FundCombination.vue"
          ),
      },
      {
        path: "fund-combination-fund-type",
        name: "fund-combination-fund-type",
        component: () =>
          import(
            /* webpackChunkName: "fund-combination-fund-type" */ "../views/MasterData/FundCombinationFundType.vue"
          ),
      },
      {
        path: "file-type",
        name: "file-type",
        component: () =>
          import(
            /* webpackChunkName: "file-type" */ "../views/MasterData/FileType.vue"
          ),
      },
      {
        path: "action-subgroup-file-type",
        name: "action-subgroup-file-type",
        component: () =>
          import(
            /* webpackChunkName: "action-subgroup-file-type" */ "../views/MasterData/ActionSubgroupFileType.vue"
          ),
      },
      {
        path: "unit-measurement-type",
        name: "unit-measurement-type",
        component: () =>
          import(
            /* webpackChunkName: "unit-measurement-type" */ "../views/MasterData/UnitMeasurementType.vue"
          ),
      },
      {
        path: "unit-measurement",
        name: "unit-measurement",
        component: () =>
          import(
            /* webpackChunkName: "unit-measurement" */ "../views/MasterData/UnitMeasurement.vue"
          ),
      },
      {
        path: "stock-group",
        name: "stock-group",
        component: () =>
          import(
            /* webpackChunkName: "stock-group" */ "../views/MasterData/StockGroup.vue"
          ),
      },
      {
        path: "erp-integration",
        name: "erp-integration",
        component: () =>
          import(
            /* webpackChunkName: "erp-integration" */ "../views/MasterData/ErpIntegration.vue"
          ),
      },
      {
        path: "run-znap-erp-integration",
        name: "run-znap-erp-integration",
        component: () =>
          import(
            /* webpackChunkName: "run-znap-erp-integration" */ "../views/MasterData/ErpIntegrationRun.vue"
          ),
      },
      {
        path: "to-erp-integration",
        name: "to-erp-integration",
        component: () =>
          import(
            /* webpackChunkName: "to-erp-integration" */ "../views/MasterData/ToErpIntegration.vue"
          ),
      },
      {
        path: "to-erp-integration-run",
        name: "to-erp-integration-run",
        component: () =>
          import(
            /* webpackChunkName: "to-erp-integration-run" */ "../views/MasterData/ToErpIntegrationRun.vue"
          ),
      },
    ],
  },
  {
    path: "/refund/",
    name: "Refund",
    component: () =>
      import(/* webpackChunkName: "refund" */ "../views/Refund.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    children: [
      {
        path: "new-action-plan",
        name: "new-action-plan",
        component: () =>
          import(
            /* webpackChunkName: "new-action-plan" */ "../views/Refund/NewActionPlan.vue"
          ),
      },
      {
        path: "new-action-plan/:id",
        name: "new-action-plan-id",
        component: () =>
          import(
            /* webpackChunkName: "new-action-plan-id" */ "../views/Refund/NewActionPlan.vue"
          ),
      },
      {
        path: "new-action-plan/duplicate/:id",
        name: "new-action-plan-duplicate-id",
        component: () =>
          import(
            /* webpackChunkName: "new-action-plan-duplicate-id" */ "../views/Refund/NewActionPlan.vue"
          ),
      },
      {
        path: "action-plan",
        name: "action-plan",
        component: () =>
          import(
            /* webpackChunkName: "action-plan" */ "../views/Refund/ActionPlan.vue"
          ),
      },
      {
        path: "action-plan-item",
        name: "action-plan-item",
        component: () =>
          import(
            /* webpackChunkName: "action-plan-item" */ "../views/Refund/ActionPlanItem.vue"
          ),
      },
      {
        path: "action-plan-fund",
        name: "action-plan-fund",
        component: () =>
          import(
            /* webpackChunkName: "action-plan-fund" */ "../views/Refund/ActionPlanFund.vue"
          ),
      },
      {
        path: "action-plan-approval-flow",
        name: "action-plan-approval-flow",
        component: () =>
          import(
            /* webpackChunkName: "action-plan-approval-flow" */ "../views/Refund/ActionPlanApprovalFlow.vue"
          ),
      },
      {
        path: "action-plan-approval-level",
        name: "action-plan-approval-level",
        component: () =>
          import(
            /* webpackChunkName: "action-plan-approval-flow-level" */ "../views/Refund/ActionPlanApprovalLevel.vue"
          ),
      },
      {
        path: "status-action-plan",
        name: "status-action-plan",
        component: () =>
          import(
            /* webpackChunkName: "status-action-plan" */ "../views/Refund/StatusActionPlan.vue"
          ),
      },
      {
        path: "action-plan-file",
        name: "action-plan-file",
        component: () =>
          import(
            /* webpackChunkName: "action-plan-file" */ "../views/Refund/ActionPlanFile.vue"
          ),
      },
      {
        path: "action-plan-history",
        name: "action-plan-history",
        component: () =>
          import(
            /* webpackChunkName: "action-plan-history" */ "../views/Refund/ActionPlanHistory.vue"
          ),
      },
      {
        path: "integration-action-plan",
        name: "integration-action-plan",
        component: () =>
          import(
            /* webpackChunkName: "integration-action-plan" */ "../views/Refund/IntegrationActionPlan.vue"
          ),
      },
      {
        path: "qca-23",
        name: "qca-23",
        component: () =>
          import(
            /* webpackChunkName: "qca-23" */ "../views/Refund/ReportActionPlanQCA23.vue"
          ),
      },
      {
        path: "qca-24",
        name: "qca-24",
        component: () =>
          import(
            /* webpackChunkName: "qca-23" */ "../views/Refund/ReportActionPlanQCA24.vue"
          ),
      },
      {
        path: "extract-payment",
        name: "extract-payment",
        component: () =>
          import(
            /* webpackChunkName: "extract-payment-action-type" */ "../views/Refund/ExtractPayment.vue"
          ),
      },
      {
        path: "extract-payment-edit/:id",
        name: "extract-payment-edit",
        component: () =>
          import(
            /* webpackChunkName: "extract-payment-id" */ "../views/Refund/ExtractPaymentEdit.vue"
          ),
      },
      {
        path: "report-galery-action-plan",
        name: "report-galery-action-plan",
        component: () =>
          import(
            /* webpackChunkName: "report-galery-action-plan" */ "../views/Refund/ReportGaleryActionPlan.vue"
          ),
      },
      {
        path: "extra-event",
        name: "extra-event",
        component: () =>
          import(
            /* webpackChunkName: "action-plan-approval-flow-level" */ "../views/Refund/ExtraEvent.vue"
          ),
      },
      {
        path: "extra-event-account",
        name: "extra-event-account",
        component: () =>
          import(
            /* webpackChunkName: "action-plan-approval-flow-level" */ "../views/Refund/ExtraEventAccount.vue"
          ),
      },
      {
        path: "extra-event-customer",
        name: "extra-event-customer",
        component: () =>
          import(
            /* webpackChunkName: "action-plan-approval-flow-level" */ "../views/Refund/ExtraEventCustomer.vue"
          ),
      },
      {
        path: "extra-event-action",
        name: "extra-event-action",
        component: () =>
          import(
            /* webpackChunkName: "action-plan-approval-flow-level" */ "../views/Refund/ExtraEventAction.vue"
          ),
      },
      {
        path: "extra-event-dashboard",
        name: "extra-event-dashboard",
        component: () =>
          import(
            /* webpackChunkName: "action-plan-approval-flow-level" */ "../views/Refund/ExtraEventDashboard.vue"
          ),
      },
    ],
  },
  {
    path: "/swap/",
    name: "Swap",
    component: () => import(/* webpackChunkName: "swap" */ "../views/Swap.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    children: [
      {
        path: "new-swap",
        name: "new-swap",
        component: () =>
          import(
            /* webpackChunkName: "new-swap" */ "../views/Swap/NewSwap.vue"
          ),
      },
      {
        path: "new-swap/:id",
        name: "new-swap-id",
        component: () =>
          import(
            /* webpackChunkName: "new-swap-id" */ "../views/Swap/NewSwap.vue"
          ),
      },
      {
        path: "new-swap/duplicate/:id",
        name: "new-swap-duplicate-id",
        component: () =>
          import(
            /* webpackChunkName: "new-swap-duplicate-id" */ "../views/Swap/NewSwap.vue"
          ),
      },
      {
        path: "swap",
        name: "swap",
        component: () =>
          import(/* webpackChunkName: "swap" */ "../views/Swap/Swap.vue"),
      },
      {
        path: "swap-item",
        name: "swap-item",
        component: () =>
          import(
            /* webpackChunkName: "swap-item" */ "../views/Swap/SwapItem.vue"
          ),
      },
      {
        path: "status-swap",
        name: "status-swap",
        component: () =>
          import(
            /* webpackChunkName: "status-swap" */ "../views/Swap/StatusSwap.vue"
          ),
      },
      {
        path: "swap-file",
        name: "swap-file",
        component: () =>
          import(
            /* webpackChunkName: "swap-file" */ "../views/Swap/SwapFile.vue"
          ),
      },
      {
        path: "integration-swap",
        name: "integration-swap",
        component: () =>
          import(
            /* webpackChunkName: "integration-swap" */ "../views/Swap/IntegrationSwap.vue"
          ),
      },
      {
        path: "report-swap",
        name: "report-swap",
        component: () =>
          import(
            /* webpackChunkName: "report-swap" */ "../views/Swap/ReportSwap.vue"
          ),
      },
      {
        path: "report-gallery-swap",
        name: "report-gallery-swap",
        component: () =>
          import(
            /* webpackChunkName: "report-galery-swap" */ "../views/Swap/ReportGalerySwap.vue"
          ),
      },
      {
        path: "swap-approval-flow",
        name: "swap-approval-flow",
        component: () =>
          import(
            /* webpackChunkName: "swap-approval-flow" */ "../views/Swap/SwapApprovalFlow.vue"
          ),
      },
      {
        path: "swap-history",
        name: "swap-history",
        component: () =>
          import(
            /* webpackChunkName: "swap-history" */ "../views/Swap/SwapHistory.vue"
          ),
      },
      {
        path: "swap-approval-level",
        name: "swap-approval-level",
        component: () =>
          import(
            /* webpackChunkName: "swap-approval-flow-level" */ "../views/Swap/SwapApprovalLevel.vue"
          ),
      },
    ],
  },
  /*
  {
    path: "/approvalflow/",
    name: "ApprovalFlow",
    component: () =>
      import(
        /* webpackChunkName: "approvalflow"  "../views/ApprovalFlow.vue"
      ),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    children: [
      {
        path: "approval-flow",
        name: "approval-flow",
        component: () =>
          import(
            /* webpackChunkName: "approval-flows"  "../views/ApprovalFlow/ApprovalFlow.vue"
          ),
      },
      {
        path: "approval-flow-level",
        name: "approval-flow-level",
        component: () =>
          import(
            /* webpackChunkName: "gallery-swap"  "../views/ApprovalFlow/ApprovalFlowLevel.vue"
          ),
      },
      {
        path: "refund-approval",
        name: "refund-approval",
        component: () =>
          import(
            /* webpackChunkName: "refund-approval"  "../views/ApprovalFlow/RefundApproval.vue"
          ),
      },
      {
        path: "swap-approval",
        name: "swap-approval",
        component: () =>
          import(
            /* webpackChunkName: "swap-approval"  "../views/ApprovalFlow/SwapApproval.vue"
          ),
      },
    ],
  },*/
];
