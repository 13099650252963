<template>
  <v-layout v-resize="onResize" v-show="show">
    <v-app-bar elevation="2" class="white pr-2">
      <v-app-bar-nav-icon
        data-testid="open-nav-bar-button"
        v-if="$route.path !== '/' && $route.path !== '/profile'"
        @click.stop="drawer = !drawer"
        color="black"
        class="my-2 ml-n2 mr-2"
        min-width="48px"
        min-height="48px"
      ></v-app-bar-nav-icon>

      <div
        v-if="!isMobile"
        @click="$route.path !== '/' && $router.push('/')"
        class="d-flex align-center"
        :style="$route.path !== '/' && 'cursor: pointer;'"
      >
        <img
          alt="Logotipo Nissin"
          class="my-2 mr-2"
          width="105"
          :src="changeLogo ? '/img/nissin-logo.png' : '/img/znap-app-bar.png'"
        />
        <p v-if="isSandbox" class="sandbox-theme">Sandbox</p>
      </div>

      <v-divider
        v-if="isHomol && !isMobile"
        class="mx-4"
        vertical
        inset
      ></v-divider>

      <span
        v-if="isHomol"
        class="text-uppercase font-weight-bold black--text"
        :class="!isMobile ? 'headline' : ''"
      >
        {{ isMobile ? "SB" : "Sandbox" }}
      </span>

      <v-spacer></v-spacer>

      <v-menu
        v-if="selectedModule"
        bottom
        offset-y
        style="z-index: 1000 !important"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="mr-2" color="black">
            <v-icon color="black" class="mx-2">{{
              selectedModule.icon
            }}</v-icon>
            <span>{{ selectedModule.label }}</span>
          </v-btn>
        </template>

        <v-list style="overflow-x: hidden">
          <v-list-item-group
            v-model="selectionMenu"
            active-class="primary--text text--accent-4"
          >
            <a
              v-for="clientModule in modules"
              :key="clientModule.label"
              class="text-decoration-none"
              :href="clientModule.link"
            >
              <v-layout align-center>
                <v-flex xs11>
                  <v-list-item
                    @click="
                      $route.path.split('/')[1] !==
                      clientModule.path.split('/')[1]
                        ? navigateMenu(clientModule)
                        : false
                    "
                    @click.prevent="
                      $nextTick(() => {
                        selectionMenu = null;
                      })
                    "
                    :class="
                      $route.path.split('/')[1] ===
                      clientModule.path.split('/')[1]
                        ? 'primary--text'
                        : ''
                    "
                  >
                    <v-list-item-icon>
                      <v-icon
                        v-text="clientModule.icon"
                        color="primary"
                      ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="clientModule.label"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
                <v-flex xs1>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-layout justify-center>
                        <a
                          :href="clientModule.link"
                          target="_blank"
                          class="text-decoration-none"
                          v-on="on"
                        >
                          <v-icon
                            class="px-2"
                            color="primary"
                            style="margin-left: -8px !important"
                            small
                            >mdi-open-in-new</v-icon
                          >
                        </a>
                      </v-layout>
                    </template>
                    <span>Abrir em nova aba</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </a>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-menu v-if="windowSize.x > 1" bottom offset-y style="z-index: 12">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-badge
              avatar
              bottom
              :color="sessionBadgeColor"
              offset-x="18"
              offset-y="18"
            >
              <template v-if="getUser">
                <v-avatar v-if="getUser.photo">
                  <v-img :src="getUser.photo" :aspect-ratio="1 / 1">
                    <template v-slot:placeholder>
                      <v-skeleton-loader type="avatar"></v-skeleton-loader>
                    </template>
                  </v-img>
                </v-avatar>
                <v-avatar v-else>
                  <v-icon color="primary" class="text-h3"
                    >mdi-account-circle</v-icon
                  >
                </v-avatar>
              </template>
              <v-avatar v-else>
                <v-icon color="primary" class="text-h3"
                  >mdi-account-circle</v-icon
                >
              </v-avatar>
            </v-badge>
          </v-btn>
        </template>

        <v-sheet max-width="350px">
          <v-list>
            <v-list-item-group>
              <v-list-item @click="goToProfile">
                <v-list-item-icon class="mr-4">
                  <template v-if="getUser">
                    <v-avatar v-if="getUser.photo">
                      <v-img
                        :src="getUser.photo"
                        :alt="'Foto de ' + getUser.name"
                        :aspect-ratio="1 / 1"
                      />
                    </v-avatar>
                    <v-icon v-else color="gray darken-2" x-large
                      >mdi-account-circle</v-icon
                    >
                  </template>
                  <v-icon v-else color="gray darken-2" x-large
                    >mdi-account-circle</v-icon
                  >
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="text-h6">{{
                    getUser ? getUser.name : ""
                  }}</v-list-item-title>
                  <v-list-item-title class="text-caption">{{
                    getUser ? getUser.email : ""
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-row class="py-2 px-4">
                <v-col class="d-flex align-center">
                  <v-icon small color="primary" class="mr-2">mdi-clock</v-icon>
                  <span class="text-caption">{{ `${tokenExp()}` }}</span>
                  <v-spacer></v-spacer>
                  <v-btn x-small text color="primary" @click="renewSession()">
                    Renovar
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-list-item @click="logout">
                <v-list-item-content>
                  <v-list-item-title>Sair</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </v-menu>

      <v-divider
        v-if="windowSize.x > 600"
        class="ml-6 mr-4"
        vertical
        inset
      ></v-divider>

      <a
        v-if="windowSize.x > 600"
        href="https://znap.com.br/"
        style="text-decoration: none"
        class="d-flex align-center"
        target="_blank"
      >
        <img
          src="/img/znap-app-bar.png"
          alt="Logotipo Znap Technologies"
          style="max-width: 95px"
        />
      </a>
    </v-app-bar>

    <v-navigation-drawer
      style="z-index: 99999"
      v-model="drawer"
      temporary
      app
      light
      width="500"
    >
      <v-container fluid fill-height class="pa-0 ma-0">
        <v-layout column>
          <div class="d-flex">
            <v-app-bar-nav-icon
              data-testid="close-nav-bar-button"
              @click.stop="drawer = !drawer"
              color="black"
              class="my-2 ml-1"
              min-width="48px"
              min-height="48px"
            ></v-app-bar-nav-icon>

            <img
              class="mx-2 my-2"
              width="105"
              :src="
                changeLogo ? '/img/nissin-logo.png' : '/img/znap-app-bar.png'
              "
              alt="Logotipo Nissin"
            />
          </div>

          <v-divider></v-divider>

          <div
            v-if="loadingMenu"
            class="d-flex align-center justify-center pa-4 mt-8"
          >
            <v-progress-circular
              indeterminate
              size="24"
              width="3"
              color="primary"
            />
          </div>

          <v-list v-else>
            <div v-for="item in parents" :key="item.transactionCode">
              <v-list-item
                @click="handleParentMenuClick(item)"
                :class="{
                  'v-list-item--active': item.menus
                    ? childIsSelected(item)
                    : parentIsSelected(item),
                }"
                data-testid="menu-list-item"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="black--text">
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action
                  v-if="!item.menus"
                  @click.stop="openInNewTab(item)"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon>
                        <v-icon v-on="on" color="black" small>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Abrir em nova aba</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>

              <v-expand-transition>
                <v-list v-show="item.expand" class="ml-14">
                  <v-list-item
                    @click.prevent="navigate(menu)"
                    v-for="menu in item.menus"
                    :key="menu.transactionCode"
                    class="text-decoration-none"
                    active-class=""
                  >
                    <v-list-item-icon
                      v-if="
                        $route.path ===
                        `/${currentModule}${menu.frontend_route_path}`
                      "
                      class="mx-0"
                    >
                      <v-icon
                        color="primary"
                        style="margin-left: -8px !important"
                      >
                        mdi-menu-right
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content data-testid="menu-list-submenu-item">
                      <v-list-item-title
                        class="text-subtitle-2 font-weight-regular black--text"
                      >
                        {{ menu.label }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action @click.stop="openInNewTab(menu)">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon>
                            <v-icon v-on="on" color="black" small>
                              mdi-open-in-new
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Abrir em nova aba</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-expand-transition>
            </div>
          </v-list>

          <v-spacer></v-spacer>

          <v-footer color="transparent">
            <v-layout align-center justify-center>
              <p class="text-caption text-center black--text">
                v. {{ appVersion }}
              </p>
            </v-layout>
          </v-footer>
        </v-layout>
      </v-container>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment";
import configs from "@/configs";
import packageJsonProps from "../../package";
// import setBaseUrl from "../helpers/setBaseUrl";

export default {
  name: "AppBar",

  data() {
    return {
      windowSize: { x: 0, y: 0 },
      menuItems: [{ text: this.$vuetify.lang.t("$vuetify.logout"), link: "/" }],

      // session exp
      now: new Date(),
      sessionBadgeColor: "success",

      isSandbox: configs.env !== "prod",
      changeLogo: configs.env === "prod" || configs.env === "homol",
      loadingMenu: false,
      modules: [],

      selectionMenu: null,

      isHomol: false,

      show: true,

      drawer: false,
      parents: [],
      appVersion: packageJsonProps.version,

      latestPeriodStatus: null,
    };
  },

  computed: {
    ...mapGetters("theme", ["getLogos"]),
    ...mapGetters("auth", ["getTokenExp", "getUser", "getHash"]),

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    selectedModule() {
      if (!this.modules.length) {
        return null;
      }

      const current = this.modules.find((item) => {
        if (item.path) {
          const path = item.path.replaceAll("/", "");
          return path === this.currentModule;
        }
      });

      return current ? { icon: current.icon, label: current.label } : null;
    },

    currentModule() {
      return this.$route.path.split("/")[1];
    },

    currentRoutePath() {
      return this.$route.path;
    },
  },

  watch: {
    getUser: {
      immediate: true,
      handler(val) {
        if (val.id_user) {
          this.listModules();
        }
      },
    },

    currentModule: {
      immediate: true,
      handler(val) {
        if (val && this.$route.path !== "/profile") {
          this.listMenu();
        }
      },
    },

    currentRoutePath: {
      immediate: true,
      handler(route) {
        const mainViews = [
          "/security",
          "/masterdata",
          "/refund",
          "/swap",
          "/approvalflow",
        ];

        if (mainViews.includes(route)) {
          this.drawer = true;
        }
      },
    },
  },

  created() {
    this.$root.$on("hideAppBar", ($event) => (this.show = !$event));
  },

  mounted() {
    setInterval(() => {
      this.now = new Date();
    }, 60000);

    if (configs.env === "homol") {
      this.isHomol = true;
    }
  },

  beforeUnmount() {
    clearInterval();
  },

  methods: {
    ...mapMutations("auth", ["setSessionExpiredDialog"]),
    ...mapActions("auth", [
      "setHash",
      "hashAuthenticate",
      "setModulesObject",
      "setTableObject",
      "logout",
    ]),

    goToProfile() {
      if (configs.env === "prod" || configs.env === "homol") {
        window.open(configs.portal_url, "_blank");
      } else {
        this.$route.path !== "/profile" ? this.$router.push("/profile") : false;
      }
    },
    tokenExp() {
      let tokenExp = "";

      if (
        this.getTokenExp &&
        !this.isValidToken(this.getTokenExp) &&
        (configs.env === "prod" || configs.env === "homol")
      ) {
        window.location.href = configs.portal_url;
      } else if (this.getTokenExp && !this.isValidToken(this.getTokenExp)) {
        this.setSessionExpiredDialog(true);

        tokenExp = "Sessão expirada";
        return tokenExp;
      }

      let now = moment(this.now, "hh:mm:ss");
      let exp = moment.unix(this.getTokenExp, "hh:mm:ss");
      let duration = moment.duration(exp.diff(now))._data;

      let hours = duration.hours.toString().padStart(2, "0");
      let minutes = duration.minutes.toString().padStart(2, "0");
      let time = `${hours}h${minutes}m`;

      this.setSessionBadgeColor(hours, minutes);

      tokenExp = `Sessão expira em ${time}`;

      return tokenExp;
    },

    setSessionBadgeColor(hours, minutes) {
      if (!hours || !minutes) {
        return (this.sessionBadgeColor = "error");
      }

      let h = parseInt(hours);
      let m = parseInt(minutes);

      if (h === 0) {
        if (m >= 10) {
          this.sessionBadgeColor = "success";
        } else if (m < 10 && m > 5) {
          this.sessionBadgeColor = "warning";
        } else if (m < 5) {
          this.sessionBadgeColor = "error";
        }
      } else {
        this.sessionBadgeColor = "success";
      }
    },
    getUrlRouter(isTorii = false) {
      let url = null;

      let env = "";

      if (configs.env === "homol") {
        env = "-homol";
      } else if (configs.env === "dev") {
        env = ".dev";
      }

      if (window.location.href.includes(":80")) {
        url = `http://localhost:${isTorii ? "8080" : "8081"}`;
      } else if (configs.env === "prod" || configs.env === "homol") {
        url = `https://${
          isTorii ? configs.url : configs.urlInvestmentPlan
        }${env}.nissin.com.br`;
      } else {
        url = `https://${
          isTorii ? configs.url : configs.urlInvestmentPlan
        }${env}.znaptech.com`;
      }

      return url;
    },

    isValidToken(tokenExp) {
      let now = Math.floor(Date.now() / 1000);
      return now - tokenExp <= 0;
    },

    async renewSession() {
      let hash = this.getHash;

      const auth = await this.hashAuthenticate(hash);
      if (auth === "success") {
        this.$toast.success("Sessão renovada com sucesso");
      } else {
        let err = auth;
        this.$fnError(err);
      }
    },

    async listModules() {
      const payload = {
        module: "TORII_PORTAL",
      };

      const res = await this.$http.post(this.$ipUser + "user/menu", payload);
      if (res) {
        this.modules = res.data;

        this.setModulesObject(this.modules);

        let url = this.getUrlRouter(true);
        let urlLocal = this.getUrlRouter();

        this.modules.forEach((module) => {
          let urlUsed = url;

          if (module.label === "PLANO DE INVESTIMENTO") urlUsed = urlLocal;

          module.link = urlUsed + module.path;
        });
      }
    },

    navigateMenu(menu) {
      window.location.href = menu.link;
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },

    // drawer
    setPayloadModule(moduleName) {
      if (moduleName.includes("security")) {
        return "TORII_SECURITY";
      }

      if (moduleName.includes("masterdata")) {
        return "TORII_MASTER_DATA";
      }

      if (moduleName.includes("refund")) {
        return "TORII_REFUND";
      }

      if (moduleName.includes("swap")) {
        return "TORII_SWAP";
      }

      if (moduleName.includes("approvalflow")) {
        return "TORII_APPROVAL_FLOW";
      }
    },

    async listMenu() {
      try {
        this.loadingMenu = true;

        this.parents = [];

        // await this.getPeriods();

        const payload = {
          module: this.setPayloadModule(this.currentModule),
        };

        const res = await this.$http.post(this.$ipUser + "user/menu", payload);
        if (res) {
          let newSwapMenu = res.data.find(
            (m) => m.frontend_route_path === "/new-swap"
          );

          const items = res.data.filter(
            (item) => item.transactionCode !== "LOGIN" && !item.hideMenu
          );

          const childs = items.filter(
            (i) => !i.transactionCode.includes("MENU_GROUP")
          );

          if (items.length !== childs.length) {
            // menu agrupado
            this.parents = [
              ...items.filter((i) => !i.transactionCode.includes("MENU_GROUP")),
              ...items
                .filter((i) => i.transactionCode.includes("MENU_GROUP"))
                .map((p) => {
                  return {
                    ...p,
                    expand: false,
                    menus: childs.filter(
                      (c) => c.id_transaction_parent === p.id_transaction_parent
                    ),
                  };
                }),
            ];
          } else {
            // menu não agrupado
            const activeFunc = false;

            if (
              newSwapMenu &&
              this.latestPeriodStatus === "Fechado" &&
              activeFunc
            ) {
              this.parents = items.filter(
                (item) => item.frontend_route_path !== "/new-swap"
              );
            } else {
              this.parents = items;
            }
          }

          let menuItems = this.parents.filter((value) => value.menus);
          let menuFilters = [];

          for (const menuItem of menuItems) {
            menuFilters.push(
              ...menuItem.menus.map((value) => value.frontend_route_path)
            );
          }

          this.parents = this.parents.filter(
            (value) => !menuFilters.includes(value.frontend_route_path)
          );

          this.setTableObject(items);
        }
      } catch (error) {
        this.$fnError(error);
      } finally {
        this.loadingMenu = false;
      }
    },

    navigate(menu) {
      if ("/" + this.$route.path.split("/")[2] !== menu.frontend_route_path) {
        this.$router.push({ name: menu.frontend_route_path.substr(1) });
        this.drawer = false;
        this.parents = this.parents.map((p) => {
          return {
            ...p,
            expand: false,
          };
        });
      }
    },

    openInNewTab(menu) {
      let url = this.getUrlRouter(true);

      window.open(
        `${url}/${this.currentModule}${menu.frontend_route_path}`,
        "_blank"
      );
    },

    childIsSelected(parent) {
      const routePath = this.$route.path.split("/");
      if (routePath.length < 3) {
        return false;
      }

      const found = parent.menus.find(
        (c) => c.frontend_route_path === `/${routePath[2]}`
      );

      return !!found;
    },

    parentIsSelected(parent) {
      return (
        this.$route.path ===
        `/${this.currentModule}${parent.frontend_route_path}`
      );
    },

    handleParentMenuClick(item) {
      if (item.menus) {
        // menu agrupado
        item.expand = !item.expand;
      } else {
        // menu não agrupado
        this.$router.push(`/${this.currentModule}${item.frontend_route_path}`);
      }
    },

    // TODO tableRows + page
    async getPeriods() {
      const payload = {
        filter: {
          page: 0,
          tableRows: 1,
          conditions: [
            {
              AndOr: "AND",
              column: "id_event_type",
              operator: "=",
              value: 2,
            },
          ],
          orderColumn: "year_month_date",
          sortDesc: true,
        },
      };
      try {
        const res = await this.$http.post(
          `${this.$ipMasterData}period-control/list`,
          payload
        );
        if (res) {
          this.periods = res.data.rows;

          const latestPeriod = res.data.rows.reduce((prev, current) => {
            return prev.year_month_date > current.year_month_date
              ? prev
              : current;
          });

          this.latestPeriodStatus = latestPeriod.status;
        }
      } catch (err) {
        this.$fnError(err);
      }
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
  color: gray !important;
}

.sandbox-theme {
  margin-bottom: 0 !important;
  font-size: 24px;
  font-weight: 600;
}
</style>
